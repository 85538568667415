// extracted by mini-css-extract-plugin
export var pageWrapper = "_navbar-module--pageWrapper--OBakR";
export var footer = "_navbar-module--footer--1CmvL";
export var socialIcon = "_navbar-module--social-icon--1e0HP";
export var inner = "_navbar-module--inner--3G0WH";
export var nav = "_navbar-module--nav--2ZiCo";
export var nav__item = "_navbar-module--nav__item--2Lg1_";
export var nav__logo = "_navbar-module--nav__logo--133hk";
export var logo = "_navbar-module--logo--Man6j";
export var logoText = "_navbar-module--logoText--1mSXH";
export var navbar = "_navbar-module--navbar--2jaUB";
export var navbarNav = "_navbar-module--navbar-nav--3Mb-l";
export var navLink = "_navbar-module--nav-link--1GgWm";
export var navbarCollapse = "_navbar-module--navbar-collapse--19BEI";
export var navbarToggler = "_navbar-module--navbar-toggler--1QY41";
export var navbarTogglerIcon = "_navbar-module--navbar-toggler-icon--1vVS5";
export var navbarNavScroll = "_navbar-module--navbar-nav-scroll--G6lya";
export var offcanvas = "_navbar-module--offcanvas--2A0u9";
export var offcanvasHeader = "_navbar-module--offcanvas-header--1pA8f";
export var offcanvasTitle = "_navbar-module--offcanvas-title--2asTF";
export var offcanvasBody = "_navbar-module--offcanvas-body--3_dHO";
export var offcanvasEnd = "_navbar-module--offcanvas-end--13vjk";
export var show = "_navbar-module--show--1VrTL";
export var navbarExpand = "_navbar-module--navbar-expand--3tBLo";