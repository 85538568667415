// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-contentful-blog-post-id-js": () => import("./../../../src/pages/blog/{ContentfulBlogPost.id}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-id-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-fncs-index-js": () => import("./../../../src/pages/fncs/index.js" /* webpackChunkName: "component---src-pages-fncs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-novos-js": () => import("./../../../src/pages/novos.js" /* webpackChunkName: "component---src-pages-novos-js" */),
  "component---src-pages-premium-js": () => import("./../../../src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */)
}

