import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          canonicalSiteUrlProd: siteUrl
          defaultImage: image
          twitterUsername
        }
      }
    }
  `);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    canonicalSiteUrlProd,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  /** @param {string} url */
  function normalizeUrl(url) {
    if (url.startsWith('//')) {
      // protocol-relative, but always assume https
      return `https:${url}`;
    } else if (url.startsWith('/')) {
      // site-relative, add canonical root
      return `${canonicalSiteUrlProd}${url}`;
    } else {
      return url;
    }
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${normalizeUrl(image || defaultImage)}`,
    url: `${canonicalSiteUrlProd}${pathname}`,
  }
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* OpenGraph */}
      {seo.url &&
        <meta property="og:url" content={seo.url} />}
      {seo.title &&
        <meta property="og:title" content={seo.title} />}
      {seo.description &&
        <meta property="og:description" content={seo.description} />}
      {seo.image &&
        <meta property="og:image" content={seo.image} />}

      {/* OpenGraph article type */}
      {article &&
        <meta property="og:type" content="article" />}
      {article?.publishedTime &&
        <meta property="article:published_time" content={article.publishedTime} />}
      {article?.modifiedTime &&
        <meta property="article:modified_time" content={article.modifiedTime} />}
      {article?.expirationTime &&
        <meta property="article:expiration_time" content={article.expirationTime} />}
      {article?.section &&
        <meta property="article:section" content={article.section} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername &&
        <meta name="twitter:creator" content={twitterUsername} />}
      {seo.title &&
        <meta name="twitter:title" content={seo.title} />}
      {seo.description &&
        <meta name="twitter:description" content={seo.description} />}
      {seo.image &&
        <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    publishedTime: PropTypes.string, // When the article was first published
    modifiedTime: PropTypes.string, // When the article was last changed
    expirationTime: PropTypes.string, // When the article is out of date after
    section: PropTypes.string, // A high-level section name. E.g. Technology
  })]),
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: null,
}
