import React, { useRef } from 'react';
import { Helmet } from "react-helmet"
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faDiscord, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons"

import * as styles from './default.module.scss';
import * as navbarStyles from './_navbar.module.scss';
import Seo from '../components/seo';
import logo from '../assets/logo.png';
import novosLogo from '../assets/novos-logo.svg';
import novosPinkLogoSVG from '../assets/novos-pink-logo.svg';
import novosPinkLogo from '../assets/novos-pink-logo.png';
import menuIcon from '../assets/icons/menu.svg';
import crossIcon from '../assets/icons/cross.svg';

export const DefaultLayout = ({ children }) => {
  const navOffcanvasRef = useRef(null);

  function toggleNav () {
    const element = navOffcanvasRef.current
    if (!element) return
    if (element.classList.contains(navbarStyles.show)) {
      element.classList.remove(navbarStyles.show)
      element.style.visibility = 'hidden'
    } else {
      element.classList.add(navbarStyles.show)
      element.style.visibility = 'visible'
    }
  }

  const socialIcons = (
    <div className="flex text-xl">
      <div className={styles.socialIcon}>
        <a className={styles.inner} href="https://twitter.com/KinchAnalytics" target="_blank">
          <FontAwesomeIcon className="text-ka-cyan" icon={faTwitter} /></a>
      </div>
      <div className={styles.socialIcon}>
        <a className={styles.inner} href="https://discord.com/invite/D9BJNWy3ej" target="_blank">
          <FontAwesomeIcon className="text-ka-cyan" icon={faDiscord} /></a>
      </div>
      <div className={styles.socialIcon}>
        <a className={styles.inner} href="https://www.instagram.com/kinchanalytics/" target="_blank">
          <FontAwesomeIcon className="text-ka-cyan" icon={faInstagram} /></a>
      </div>
      <div className={styles.socialIcon}>
        <a className={styles.inner} href="https://www.tiktok.com/@kinchanalytics" target="_blank">
          <FontAwesomeIcon className="text-ka-cyan" icon={faTiktok} /></a>
      </div>
    </div>
  );

  return (
  <>
  <div className={styles.pageWrapper}>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    </Helmet>
    <Seo />

    <nav className={[navbarStyles.nav, navbarStyles.navbar, navbarStyles.navbarExpand].join(' ')}>
      <div>
        <Link className={navbarStyles.nav__item} to="/">
          <div className={navbarStyles.nav__logo}>
            <img className={navbarStyles.logo} src={logo} alt="kinch analytics" />
            <div className={navbarStyles.logoText}>KINCH<br />ANALYTICS
            </div>
          </div>
        </Link>
      </div>

      <button className={navbarStyles.navbarToggler} type="button" aria-controls="navbarOffcanvas" onClick={toggleNav}>
        <img className={navbarStyles.navbarTogglerIcon} src={menuIcon} width="32" height="32" />
      </button>
      <div className={[navbarStyles.offcanvas, navbarStyles.offcanvasEnd].join(' ')} tabIndex="-1" ref={navOffcanvasRef} id="navbarOffcanvas" aria-labelledby="navbarOffcanvasLabel">
        <div className={navbarStyles.offcanvasHeader}>
          <span className={navbarStyles.offcanvasTitle} id="navbarOffcanvasLabel">MENU</span>
          <button type="button" className={navbarStyles.navbarToggler} aria-label="Close"
            onClick={toggleNav}
          >
            <img className={navbarStyles.navbarTogglerIcon} src={crossIcon} width="20" height="20" />
          </button>
        </div>
        <div className={navbarStyles.offcanvasBody}>
          <ul className={[navbarStyles.navbarNav, "md:gap-6 xl:gap-8 text-white font-medium"].join(' ')}>
            <li className={navbarStyles.navLink}>
              <Link className={navbarStyles.nav__item} onClick={toggleNav} to="/">Home</Link>
            </li>
            <li className={navbarStyles.navLink}>
              <Link className={navbarStyles.nav__item} onClick={toggleNav} to="/fncs">FNCS</Link>
            </li>
            {/* <li className={navbarStyles.navLink}>
              <Link className={navbarStyles.nav__item} onClick={toggleNav} to="/blog">Blog</Link>
            </li> */}
            <li className={navbarStyles.navLink}>
              <Link className={navbarStyles.nav__item} onClick={toggleNav} to="/premium">Kinch Premium</Link>
            </li>
            <li className={navbarStyles.navLink}>
              <Link className={navbarStyles.nav__item} onClick={toggleNav} to="/about">About</Link>
            </li>
            <li className={navbarStyles.navLink}>
              {socialIcons}
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <main>
      {children}
    </main>

    <footer>
      <div className="flex flex-wrap justify-between items-center">
        <div id="footer-left" className="order-1">
          {socialIcons}
        </div>
        <div id="footer-middle" className="order-2 w-full md:order-1 md:w-auto">
          <div className="font-medium text-xs md:text-center text-ka-dim mt-7 md:mt-0">
            Fortnite is a registered trademark of Epic Games.<br />
            Kinch Analytics is not associated or endorsed by Epic Games.
          </div>
        </div>
        {/* <div id="footer-right" className="order-1">
          <a className="no-underline" href="https://novos.gg/?utm=kinchanalytics_footer" target="_blank">
            <div className="text-footer text-right text-ka-dim mb-1">In partnership with</div>
            <img style={{height:20}} src={novosPinkLogoSVG} alt="NOVOS.gg"/>
          </a>
        </div> */}
      </div>
    </footer>
  </div>
  </>
  );
}

export default DefaultLayout;
