import React from "react"
import DefaultLayout from "./src/layouts/default"
import './src/styles/global.scss';
import './src/styles/common.scss';

export function wrapPageElement({ element, props }) {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <DefaultLayout {...props}>{element}</DefaultLayout>
}
